<script setup>
import { useWindowScroll } from "@vueuse/core";

const navbar = ref(null);
const route = useRoute();
const toggleNavigation = ref(false);
const { y } = useWindowScroll();

const navClass = computed(() => {
  const baseClasses = [
    "px-2 sm:px-4 py-2.5",
    "dark:bg-gray-900 w-full z-20 fixed",
    "border-gray-200 dark:border-gray-600 select-none bg-transparent",
  ];

  return [...baseClasses].join(" ");
});

const toggleDropdown = () => {
  toggleNavigation.value = !toggleNavigation.value;
};
</script>

<template>
  <header>
    <nav
      ref="navbar"
      :class="[{ 'border-b bg-white top-0': y > 0 }, navClass]"
      class="z-50 transition-top duration-300 ease-in-out"
    >
      <div class="container flex flex-wrap items-center justify-between mx-auto">
        <!-- <NavbarHomeLogo /> -->
        <NavbarHomeCustomLottieLogo />

        <!-- Right side actions -->
        <div class="flex items-center md:order-2">
          <CustomNavButton />
          <Button
            @click="toggleDropdown"
            icon="pi pi-bars text-xl"
            class="p-button-icon !text-gray-500 !p-2 !bg-gray-50 rounded-lg md:hidden hover:!bg-gray-100 focus:outline-none focus:!ring-2 focus:!ring-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          />
        </div>

        <!-- LINKS -->
        <div
          class="items-center justify-between sticky w-full md:flex md:w-auto md:order-1"
          :class="{ hidden: !toggleNavigation }"
          id="navbar-sticky"
        >
          <NavbarHomeLinks
            :toggleNavigation="toggleNavigation"
            @toggleDropdownCallback="toggleNavigation = false"
          />
        </div>
      </div>
    </nav>
    <CustomGoogleOneTapLogin />
  </header>
</template>

<style></style>
